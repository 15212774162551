/**
 * ログイン用JS
 */

class Login {
    constructor() {
        // required blur
        this.requiredEls = Array.from(document.querySelectorAll(`
            input[type=text].required,
            input[type=email].required,
            input[type=tel].required,
            input[type=password].required,
            textarea.required
        `));
        this.requiredEls.forEach(el => el.addEventListener('blur', () => this.validate(el)));

        // ページ初期化
        this.init();
    }

    /**
     * ページ初期化時に実行
     */
    init(){
        // バリデーションエラー表示
        let firstErr;
        document.querySelectorAll('.errMsg').forEach(el => {
            const nextEl = el.nextElementSibling;
            const prevEl = el.previousElementSibling;
            if( el.textContent != '' ){
                if( nextEl ){
                    if( nextEl.tagName == 'INPUT' || nextEl.tagName == 'TEXTAREA' ){
                        nextEl.style.backgroundColor = '#FFDDDD';
                    }
                    nextEl.style.marginTop = '10px';
                }else if( prevEl ){
                    if( prevEl.tagName == 'INPUT' || prevEl.tagName == 'TEXTAREA' ){
                        prevEl.style.backgroundColor = '#FFDDDD';
                    }
                    prevEl.style.marginBottom = '10px';
                }
            }
        });
    }

    /**
     * 入力チェック
     */
    validate(el){
        let errorMsgEl;
        if( el.previousElementSibling && el.previousElementSibling.classList.contains('errMsg') ){
            errorMsgEl = el.previousElementSibling;
        }else if( el.closest('ul') && el.closest('ul').previousElementSibling.classList.contains('errMsg') ){
            errorMsgEl = el.closest('ul').previousElementSibling;
        }else if( el.nextElementSibling && el.nextElementSibling.classList.contains('errMsg') ){
            errorMsgEl = el.nextElementSibling;
        }

        if( errorMsgEl ){
            if( el.value == '') {
                el.style.backgroundColor = '#FFDDDD';
            }else{
                el.style.backgroundColor = '#FFF';
                el.style.marginTop = 0;
                errorMsgEl.textContent = '';
            }
        }
    }
}

const login = new Login();
